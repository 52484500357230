.narrowNav {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.navbar-brand {
  padding: 18px 20px;
  display: inline-block;
}
